/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onInitialClientRender = () => {
    // Load the external script
    const script = document.createElement('script');
    script.src = 'https://ncaptcha.xyz/n-captcha/n-captcha@stable.js';
    document.body.appendChild(script);

    // Wait for the script to load before calling the function
    script.onload = () => {
        if (typeof window.initNCaptcha === 'function') {
            window.initNCaptcha();
        }
    };
};
